.content_box {
  width: 100%;
  height: 100%;
  position: relative;
}
.content_box .share_icon {
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  top: 0.26rem;
  right: 0.26rem;
}
.content_box .music-icon {
  position: absolute;
  top: 1.1rem;
  right: 0.26rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.6rem;
  height: 0.6rem;
  background-color: #ffffff;
  border-radius: 50%;
}
.content_box .music-icon.active {
  animation: musicRotate 6s linear infinite;
}
.content_box .music-icon svg {
  width: 0.4rem;
}
.content_box .top_box {
  width: 100%;
  height: 2.1rem;
  position: relative;
}
.content_box .top_bg {
  width: 100%;
  height: 100%;
}
.content_box .top_title {
  width: 5.83rem;
  position: absolute;
  top: 0.83rem;
  left: 50%;
  transform: translateX(-50%);
}
.content_box .main_box {
  width: 100%;
  height: 9.47rem;
  position: relative;
  overflow: hidden;
}
.content_box .main_kuang {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}
.content_box .avatar_view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.content_box .footer_box {
  width: 100%;
  font-size: 0.2rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  position: absolute;
  bottom: 0.1rem;
  left: 0;
  z-index: 3;
}
.content_box .footer_box .footer_info {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.content_box .footer_box img {
  width: 1.75rem;
  margin-left: 1rem;
}
.content_box .footer_box span {
  display: inline-block;
}
.content_box .footer_box .footer_txt {
  margin-right: 1rem;
  opacity: 0.8;
}
.content_box .footer_box .footer_desc {
  width: 100%;
  text-align: center;
  padding-bottom: 0.43rem;
}
.content_box .receive-coupon-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3;
}
.content_box .receive-coupon-mask .coupon-content {
  width: 6rem;
  margin: 0 auto;
  padding-top: 2.4rem;
}
.content_box .receive-coupon-mask .coupon-content .coupon-content-content {
  position: relative;
  width: 100%;
  margin-bottom: 0.5rem;
}
.content_box .receive-coupon-mask .coupon-content .coupon-content-content img {
  width: 100%;
}
.content_box .receive-coupon-mask .coupon-content .coupon-content-content .bottom-content {
  position: absolute;
  left: 50%;
  bottom: 0.4rem;
  width: 4rem;
  transform: translateX(-50%);
}
.content_box .receive-coupon-mask .coupon-content .coupon-content-content .bottom-content .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.8rem;
  font-size: 0.34rem;
  color: #ffffff;
  background-color: #FF9FA9;
  border-radius: 0.4rem;
}
.content_box .receive-coupon-mask .coupon-content .close {
  text-align: center;
}
.content_box .receive-coupon-mask .coupon-content .close img {
  width: 0.64rem;
}
.poster_dialog {
  text-align: center;
}
.poster_img {
  width: 5rem;
  height: 8.5rem;
  margin: 0.2rem 0;
}
.poster_desc {
  font-size: 0.2rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin-bottom: 0.2rem;
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .content_box .top_box {
    height: 2.1rem;
  }
  .content_box .top_title {
    top: 0.8rem;
  }
  .content_box .main_box {
    height: 9.4rem;
  }
}
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .content_box .top_box {
    height: 2.1rem;
  }
  .content_box .top_title {
    top: 0.8rem;
  }
  .content_box .main_box {
    height: 9.4rem;
  }
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .content_box .top_box {
    height: 2.1rem;
  }
  .content_box .top_title {
    top: 0.8rem;
  }
  .content_box .main_box {
    height: 9.4rem;
  }
}
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .content_box .top_box {
    height: 2.1rem;
  }
  .content_box .top_title {
    top: 0.8rem;
  }
  .content_box .main_box {
    height: 9.4rem;
  }
}
@media only screen and (device-width: 360px) and (device-height: 780px) and (-webkit-device-pixel-ratio: 3) {
  .content_box .top_box {
    height: 2.1rem;
  }
  .content_box .top_title {
    top: 0.8rem;
  }
  .content_box .main_box {
    height: 9.4rem;
  }
}
@media only screen and (device-width: 360px) and (device-height: 800px) and (-webkit-device-pixel-ratio: 3) {
  .content_box .top_box {
    height: 2.1rem;
  }
  .content_box .top_title {
    top: 0.8rem;
  }
  .content_box .main_box {
    height: 9.4rem;
  }
}
@keyframes musicRotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
