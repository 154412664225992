.create_box {
  width: 100%;
  height: 100%;
  position: relative;
}
.create_box .has_padding {
  width: 6.57rem;
  margin: 0 auto;
  padding: 0.2rem 0;
  border-radius: 0.5rem;
}
.create_box .top_box {
  width: 100%;
  height: 1rem;
  position: relative;
}
.create_box .top_bg {
  width: 100%;
  height: 100%;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}
.create_box .top_title {
  width: 4rem;
  position: absolute;
  top: 0.3rem;
  left: 50%;
  transform: translateX(-50%);
}
.create_box .main_box {
  width: 100%;
  height: 8.2rem;
  margin-top: -1px;
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.create_box .add_avatar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2.63rem;
  height: 0.88rem;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  line-height: 0.88rem;
  justify-content: center;
  cursor: pointer;
  z-index: 3;
  vertical-align: middle;
}
.create_box .add_icon {
  font-size: 0.5rem;
  margin-right: 0.2rem;
  vertical-align: middle;
  display: inline-block;
}
.create_box .main_kuang {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.create_box .avatar_view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.create_box .message {
  position: absolute;
  right: 0.2rem;
  bottom: 4.35rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  text-align: center;
  line-height: 1.7rem;
  z-index: 99;
}
.create_box .message_title {
  width: 100%;
  text-align: center;
  color: #fff;
  margin: 0.54rem 0;
}
.create_box .message_close {
  width: 0.25rem;
  height: 0.25rem;
  position: absolute;
  top: 0.35rem;
  right: 0.35rem;
}
.create_box .message_box {
  width: 100%;
  height: 4.89rem;
  background: rgba(0, 0, 0, 0.8);
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  position: absolute;
  top: 0;
  z-index: 99;
}
.create_box .message_sure {
  width: 6.39rem;
  height: 0.8rem;
  border-radius: 0.25rem;
  margin: 0 auto;
  margin-top: 0.48rem;
  font-size: 0.33rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  line-height: 0.8rem;
}
.create_box .text {
  width: 6.42rem;
  height: 1.5rem;
  border-radius: 0.2rem;
  margin: 0 auto;
}
.create_box .sticker_box {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3.7rem;
  background: #fff;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  z-index: 3;
}
.create_box .sticker_header {
  height: 0.67rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0.31rem;
}
.create_box .sticker_header span {
  font-size: 0.3rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.create_box .sticker_header img {
  width: 0.3rem;
  height: 0.25rem;
}
.create_box .sticker_list {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.1rem;
  margin: 0 0.31rem;
}
.create_box .sticker_item,
.create_box .sticker_active {
  margin-right: 0.08rem;
  border: 1px dashed transparent;
}
.create_box .sticker_item img,
.create_box .sticker_active img {
  width: 1.62rem;
  height: 2.92rem;
  vertical-align: sub;
}
.create_box .sticker_active {
  border-color: #333333;
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .create_box .top_box {
    height: 1.4rem;
  }
  .create_box .top_title {
    top: 0.5rem;
  }
  .create_box .main_box {
    height: 7.7rem;
  }
  .create_box .sticker_box {
    height: 3.4rem;
  }
  .create_box .sticker_header {
    height: 0.67rem;
  }
  .create_box .sticker_active img {
    height: 2.5rem;
  }
  .create_box .sticker_item img {
    height: 2.5rem;
  }
}
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .create_box .top_box {
    height: 1.4rem;
  }
  .create_box .top_title {
    top: 0.5rem;
  }
  .create_box .main_box {
    height: 7.7rem;
  }
  .create_box .sticker_box {
    height: 3.4rem;
  }
  .create_box .sticker_header {
    height: 0.67rem;
  }
  .create_box .sticker_active img {
    height: 2.5rem;
  }
  .create_box .sticker_item img {
    height: 2.5rem;
  }
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .create_box .top_box {
    height: 1.4rem;
  }
  .create_box .top_title {
    top: 0.5rem;
  }
  .create_box .main_box {
    height: 7.7rem;
  }
  .create_box .sticker_box {
    height: 3.4rem;
  }
  .create_box .sticker_header {
    height: 0.67rem;
  }
  .create_box .sticker_active img {
    height: 2.5rem;
  }
  .create_box .sticker_item img {
    height: 2.5rem;
  }
}
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .create_box .top_box {
    height: 1.4rem;
  }
  .create_box .top_title {
    top: 0.5rem;
  }
  .create_box .main_box {
    height: 7.7rem;
  }
  .create_box .sticker_box {
    height: 3.4rem;
  }
  .create_box .sticker_header {
    height: 0.67rem;
  }
  .create_box .sticker_active img {
    height: 2.5rem;
  }
  .create_box .sticker_item img {
    height: 2.5rem;
  }
}
@media only screen and (device-width: 360px) and (device-height: 780px) and (-webkit-device-pixel-ratio: 3) {
  .create_box .top_box {
    height: 1.4rem;
  }
  .create_box .top_title {
    top: 0.5rem;
  }
  .create_box .main_box {
    height: 7.7rem;
  }
  .create_box .sticker_box {
    height: 3.4rem;
  }
  .create_box .sticker_header {
    height: 0.67rem;
  }
  .create_box .sticker_active img {
    height: 2.5rem;
  }
  .create_box .sticker_item img {
    height: 2.5rem;
  }
}
@media only screen and (device-width: 360px) and (device-height: 800px) and (-webkit-device-pixel-ratio: 3) {
  .create_box .top_box {
    height: 1.4rem;
  }
  .create_box .top_title {
    top: 0.5rem;
  }
  .create_box .main_box {
    height: 7.7rem;
  }
  .create_box .sticker_box {
    height: 3.4rem;
  }
  .create_box .sticker_header {
    height: 0.67rem;
  }
  .create_box .sticker_active img {
    height: 2.5rem;
  }
  .create_box .sticker_item img {
    height: 2.5rem;
  }
}
