.poster_box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.poster_box .share_icon {
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  top: 0.26rem;
  right: 0.26rem;
}
.poster_box .top_box {
  width: 100%;
  height: 2.1rem;
  position: relative;
}
.poster_box .top_bg {
  width: 100%;
  height: 100%;
}
.poster_box .top_title {
  width: 5.83rem;
  position: absolute;
  top: 0.83rem;
  left: 50%;
  transform: translateX(-50%);
}
.poster_box .main_box {
  width: 100%;
  height: 9.47rem;
  position: relative;
  overflow: hidden;
}
.poster_box .main_kuang {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}
.poster_box .avatar_view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.poster_box .footer_box {
  width: 100%;
  height: 1.23rem;
  font-size: 0.2rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}
.poster_box .footer_box .footer_info {
  width: 75%;
  height: 100%;
  display: flex;
  align-items: flex-start;
}
.poster_box .footer_box img {
  width: 1.7rem;
  display: inline-block;
  margin: 0 0.3rem 0 0.7rem !important;
}
.poster_box .footer_box span {
  display: inline-block;
}
.poster_box .footer_box .footer_desc {
  width: 100%;
  text-align: center;
  padding-bottom: 0.43rem;
}
.poster_box .qr_box {
  width: 25%;
  z-index: 99;
  text-align: right;
  margin-right: 0.4rem;
}
.poster_box .qr_box #img {
  width: 1rem !important;
  height: 1rem !important;
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .poster_box .top_box {
    height: 2.1rem;
  }
  .poster_box .top_title {
    top: 0.8rem;
  }
  .poster_box .main_box {
    height: 9.4rem;
  }
  .poster_box .qr_box {
    bottom: 0.2rem;
  }
}
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .poster_box .top_box {
    height: 2.1rem;
  }
  .poster_box .top_title {
    top: 0.8rem;
  }
  .poster_box .main_box {
    height: 9.4rem;
  }
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .poster_box .top_box {
    height: 2.1rem;
  }
  .poster_box .top_title {
    top: 0.8rem;
  }
  .poster_box .main_box {
    height: 9.4rem;
  }
}
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .poster_box .top_box {
    height: 2.1rem;
  }
  .poster_box .top_title {
    top: 0.8rem;
  }
  .poster_box .main_box {
    height: 9.4rem;
  }
}
@media only screen and (device-width: 360px) and (device-height: 780px) and (-webkit-device-pixel-ratio: 3) {
  .poster_box .top_box {
    height: 2.1rem;
  }
  .poster_box .top_title {
    top: 0.8rem;
  }
  .poster_box .main_box {
    height: 9.4rem;
  }
}
@media only screen and (device-width: 360px) and (device-height: 800px) and (-webkit-device-pixel-ratio: 3) {
  .poster_box .top_box {
    height: 2.1rem;
  }
  .poster_box .top_title {
    top: 0.8rem;
  }
  .poster_box .main_box {
    height: 9.4rem;
  }
}
