.comments-btn-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0.5rem;
  display: flex;
  padding: 0 0.3rem;
  z-index: 2;
}
.comments-btn-box .voice-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 0.8rem;
  font-size: 0.32rem;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.1rem;
}
.comments-btn-box .voice-btn.active {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.8;
}
.comments-btn-box .text-input {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 0.13rem;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.1rem;
  user-select: text;
  -webkit-user-select: text;
}
.comments-btn-box .text-input input {
  width: 100%;
  border: none;
  font-size: 0.28rem;
  background-color: transparent;
}
.comments-btn-box .text-input input:-moz-placeholder,
.comments-btn-box .text-input textarea:-moz-placeholder {
  color: #ffffff;
  opacity: 0.9;
}
.comments-btn-box .text-input input:-ms-input-placeholder,
.comments-btn-box .text-input textarea:-ms-input-placeholder {
  color: #ffffff;
  opacity: 0.9;
}
.comments-btn-box .text-input input::-webkit-input-placeholder,
.comments-btn-box .text-input textarea::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 0.9;
}
.comments-btn-box .voice-svg,
.comments-btn-box .img-svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.8rem;
  height: 0.8rem;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}
.comments-btn-box .voice-svg svg,
.comments-btn-box .img-svg svg {
  width: 0.5rem;
}
.comments-btn-box .voice-svg {
  margin: 0 0.13rem;
}
