.bubble_box {
  position: absolute;
  bottom: 1.55rem;
  left: 0.28rem;
  max-width: 4.74rem;
  max-height: 3rem;
  z-index: 2;
}
.bubble_box .bubble_item {
  display: table;
  width: auto;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.2rem;
  font-size: 0.28rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  margin-bottom: 0.15rem;
  padding: 0.02rem 0;
  position: relative;
}
.bubble_box .bubble_item .avatar {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  vertical-align: middle;
  margin: 0 0.28rem;
}
.bubble_box .bubble_item span {
  padding: 0.05rem 0;
  display: inline-block;
}
.bubble_box .bubble_item .nickname {
  margin-right: 0.28rem;
}
.bubble_box .bubble_item :last-child {
  margin-right: 0.28rem;
}
.bubble_box .bubble_item .voice_icon {
  width: 0.6rem;
  display: inline-block;
  vertical-align: sub;
  margin-right: 0;
}
.bubble_box .bubble_item .voice_icon img {
  width: 0.4rem;
  height: 0.4rem;
  vertical-align: sub;
  margin-right: 0;
  position: absolute;
  top: 1px;
}
.bubble_box .bubble_item .bubble_img {
  width: 0.6rem;
  height: 0.9rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0;
}
.bubble_box .bubble_item .delete {
  display: inline-block;
  font-size: 0.3rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  padding: 0.04rem 0.1rem;
  margin: 0 0.1rem;
}
