.content {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.center-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}
